import { createContext, useCallback, useEffect, useState } from 'react';
import { capitalize } from '@brazilian-utils/brazilian-utils';

type ChangeLogoContext = {
  brand: string;
  companyName: string;
  nameTab: string;
};

const companyBrandList = [
  'interpay',
  'teto',
  'ultragate',
  'winbaas',
  '704pay',
  'speedpay',
  'tsbrazil',
  'g8pay',
  'u2pay',
  'pay2me',
  'neverra',
  'alphabank',
  'brazapay',
  'hypepay',
  'mevpay',
  'i3pay',
  'ultrapayments',
  'invpagamentos',
];

const companyFaviconList = [
  'neverra',
  'brazapay',
  'alphabank',
  'hypepay',
  'mevpay',
  'i3pay',
  'ultrapayments',
  'invpagamentos',
];

export const ChangeLogoContext = createContext({} as ChangeLogoContext);

export const ChangeLogoProvider: React.FC = ({ children }) => {
  const [brand, setBrand] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [nameTab, setNameTab] = useState('');

  useEffect(() => {
    if (companyName.toLocaleUpperCase() === 'g8pay') {
      setNameTab('G8 PAY');
    } else if (companyName.toLowerCase() === 'interpay') {
      setNameTab('iPag');
      setCompanyName('iPag');
    } else {
      setNameTab(companyName);
    }
  }, [companyName]);

  const handleChangeLogo = useCallback(async (): Promise<void> => {
    try {
      const url = window.location.hostname
        .replace('www.', '')
        .replace('beta.', '')
        .replace('.com', '')
        .replace('.br', '')
        .replace('.co', '');

      const listUrl = url.split('.');

      let isCompanyLogoFound = false;
      let companyBrand = 'Ultrapayments';

      listUrl.forEach((logo) => {
        if (companyBrandList.includes(logo) && !isCompanyLogoFound) {
          companyBrand = logo;
          isCompanyLogoFound = true;
        }
      });

      try {
        const res = await fetch(
          `${
            process.env.REACT_APP_UPLOADS
          }/images/brands/${companyBrand.toLowerCase()}.png`,
          { method: 'HEAD' }
        );

        const img = new Image();

        img.src = res.url;

        if (img.complete) {
          setBrand(
            `${
              process.env.REACT_APP_UPLOADS
            }/images/brands/${companyBrand.toLowerCase()}.png`
          );
          setCompanyName(capitalize(companyBrand.toLowerCase()));
        } else {
          img.onload = () => {
            setBrand(
              `${
                process.env.REACT_APP_UPLOADS
              }/images/brands/${companyBrand.toLowerCase()}.png`
            );
            setCompanyName(capitalize(companyBrand.toLowerCase()));
          };
          img.onerror = () => {
            setBrand('Ultrapayments');
            setCompanyName('Ultrapayments');
          };
        }
      } catch (error) {
        setBrand('Ultrapayments');
        setCompanyName('Ultrapayments');
      }
    } catch (error) {
      setBrand('Ultrapayments');
      setCompanyName('Ultrapayments');
    }
  }, []);

  const handleFavicon = useCallback(() => {
    const url = window.location.hostname
      .replace('www.', '')
      .replace('beta.', '')
      .replace('.com', '')
      .replace('.br', '')
      .replace('.co', '');

    const listUrl = url.split('.');

    let isCompanyFaviconFound = false;
    let companyFavicon: string | null = null;

    listUrl.forEach((favicon) => {
      if (companyFaviconList.includes(favicon) && !isCompanyFaviconFound) {
        companyFavicon = favicon;
        isCompanyFaviconFound = true;
      }
    });

    if (companyFavicon) {
      const href =
        require(`@src/assets/images/favicon/${companyFavicon}.ico`).default;

      const favicon =
        document.querySelector<HTMLLinkElement>("link[rel*='icon']") ||
        document.createElement('link');

      favicon.type = 'image/x-icon';
      favicon.rel = 'shortcut icon';
      favicon.href = href;
      document.getElementsByTagName('head')[0].appendChild(favicon);
    }
  }, []);

  useEffect(() => {
    handleChangeLogo();
    handleFavicon();
  }, [handleChangeLogo, handleFavicon]);

  return (
    <ChangeLogoContext.Provider value={{ brand, companyName, nameTab }}>
      {children}
    </ChangeLogoContext.Provider>
  );
};
